import React from "react";

function ContrastIcon(){
    return (
        <svg width="40" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;Contrast&#34;">
            <path id="Vector" d="M55.0688 22.2C54.5655 20.5687 53.8996 18.992 53.0812 17.4938C51.8712 15.27 50.3496 13.2307 48.5625 11.4375C44.8913 7.76665 40.214 5.26685 35.122 4.2542C30.0301 3.24156 24.7522 3.76155 19.9558 5.74843C15.1594 7.73531 11.0599 11.0998 8.17563 15.4166C5.29136 19.7333 3.75189 24.8084 3.75189 30C3.75189 35.1917 5.29136 40.2667 8.17563 44.5834C11.0599 48.9002 15.1594 52.2647 19.9558 54.2516C24.7522 56.2385 30.0301 56.7585 35.122 55.7458C40.214 54.7332 44.8913 52.2334 48.5625 48.5625C50.3496 46.7694 51.8712 44.73 53.0812 42.5063C53.8996 41.008 54.5655 39.4313 55.0688 37.8C56.6496 32.7202 56.6496 27.2799 55.0688 22.2ZM7.5 30C7.5 24.0326 9.87053 18.3097 14.0901 14.0901C18.3097 9.87054 24.0326 7.50001 30 7.50001V52.5C24.0326 52.5 18.3097 50.1295 14.0901 45.9099C9.87053 41.6903 7.5 35.9674 7.5 30Z" fill="black"/>
            </g>
        </svg>
    )
}

export default ContrastIcon;