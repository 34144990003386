import React from "react";

function ReverseContrastIcon(){
    return (
        <svg width="40" height="27" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;contrast&#34;">
            <path id="Vector" d="M28 0C12.5361 0 0 12.5361 0 28C0 43.4639 12.5361 56 28 56C43.4639 56 56 43.4639 56 28C56 12.5361 43.4639 0 28 0ZM25.4333 52.1324C21.1241 51.671 17.0165 50.066 13.5362 47.4834C10.0558 44.9008 7.32922 41.4347 5.63885 37.4441C5.59638 37.3438 5.55648 37.2429 5.51553 37.1422L25.4333 48.2929V52.1324ZM25.4333 44.0145L4.07038 32.0553C3.84569 30.7153 3.73295 29.3588 3.73333 28C3.73333 27.2291 3.76919 26.4641 3.8409 25.7052L25.4333 37.7929V44.0145ZM25.4333 33.5145L4.52888 21.812C4.82075 20.7014 5.19168 19.6131 5.63885 18.5555C5.85406 18.0463 6.08611 17.5463 6.335 17.0556L25.4333 27.759V33.5145ZM25.4333 23.4794L8.2733 13.8622C9.04589 12.7871 9.90458 11.7765 10.8409 10.8405C11.1302 10.5512 11.4258 10.2701 11.7276 9.99728L25.4333 17.5031V23.4794ZM25.4333 13.2468L14.9744 7.51928C18.1306 5.50789 21.7111 4.25777 25.4333 3.86762V13.2468ZM52.2667 28C52.2732 34.2359 49.8762 40.2344 45.5741 44.7486C41.272 49.2628 35.3956 51.9454 29.1667 52.2388V3.76122C35.3955 4.05504 41.2715 6.73779 45.5736 11.2519C49.8756 15.766 52.2727 21.7643 52.2667 28Z" fill="black"/>
            </g>
        </svg>
    )
}

export default ReverseContrastIcon;