import React from "react";

function AIcon(){
    return (
        <svg width="40" height="20" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M57.8571 51.4286H54.7219L37.2161 2.90491C36.9277 2.0576 36.3813 1.32183 35.6535 0.800851C34.9257 0.279871 34.0531 -0.000178132 33.158 8.50076e-08H26.842C25.9469 -0.000178132 25.0743 0.279871 24.3465 0.800851C23.6187 1.32183 23.0723 2.0576 22.7839 2.90491L5.27812 51.4286H2.14286C1.57454 51.4286 1.02949 51.6543 0.627628 52.0562C0.225765 52.4581 0 53.0031 0 53.5714L0 57.8571C0 58.4255 0.225765 58.9705 0.627628 59.3724C1.02949 59.7742 1.57454 60 2.14286 60H19.2857C19.854 60 20.3991 59.7742 20.8009 59.3724C21.2028 58.9705 21.4286 58.4255 21.4286 57.8571V53.5714C21.4286 53.0031 21.2028 52.4581 20.8009 52.0562C20.3991 51.6543 19.854 51.4286 19.2857 51.4286H16.6634L19.7839 42.8571H40.2161L43.3366 51.4286H40.7143C40.146 51.4286 39.6009 51.6543 39.1991 52.0562C38.7972 52.4581 38.5714 53.0031 38.5714 53.5714V57.8571C38.5714 58.4255 38.7972 58.9705 39.1991 59.3724C39.6009 59.7742 40.146 60 40.7143 60H57.8571C58.4255 60 58.9705 59.7742 59.3724 59.3724C59.7742 58.9705 60 58.4255 60 57.8571V53.5714C60 53.0031 59.7742 52.4581 59.3724 52.0562C58.9705 51.6543 58.4255 51.4286 57.8571 51.4286ZM23.6853 32.1429L30 14.8004L36.3147 32.1429H23.6853Z" fill="black"/>
        </svg>
    )
}

export default AIcon;