import React from "react";
import FindsFrame from "../scrollFrames/FindsFrame";
import "./FindsPage.css"

function FindsPage() {
    return (
        <FindsFrame/>
    )
}

export default FindsPage;