import React from "react";

function PlusGlassIcon(){
    return (
        <svg width="40" height="30" viewBox="0 0 62 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;magnifying glass plus&#34;">
            <path id="Vector" d="M35.032 28.0625H27.3276V35.9375H23.4753V28.0625H15.7709V24.125H23.4753V16.25H27.3276V24.125H35.032V28.0625ZM61.2464 59.0152L57.6138 62.7243C57.3757 62.9689 57.0927 63.1631 56.781 63.2956C56.4693 63.428 56.135 63.4962 55.7975 63.4962C55.4599 63.4962 55.1256 63.428 54.8139 63.2956C54.5022 63.1631 54.2192 62.9689 53.9811 62.7243L42.1663 50.648C41.6848 50.1555 41.4143 49.4877 41.4143 48.7914C41.4143 48.0952 41.6848 47.4274 42.1663 46.9349L42.6633 46.423L38.395 42.0642C34.8232 45.0649 30.3575 46.732 25.7344 46.7905C21.1113 46.8491 16.6066 45.2956 12.9632 42.3862C9.31968 39.4769 6.75489 35.3853 5.69165 30.7861C4.62841 26.1869 5.13019 21.3547 7.11427 17.0861C9.09834 12.8176 12.4463 9.36747 16.6061 7.30468C20.7658 5.24188 25.4892 4.68954 29.9973 5.73871C34.5054 6.78788 38.5291 9.37594 41.4052 13.0762C44.2812 16.7764 45.8378 21.3679 45.8183 26.0937C45.8007 30.9463 44.1139 35.6379 41.0531 39.3473L45.3213 43.7101L45.799 43.2218C46.2813 42.7306 46.9344 42.4548 47.6153 42.4548C48.2962 42.4548 48.9493 42.7306 49.4316 43.2218L61.2464 55.3021C61.4853 55.5457 61.6749 55.8351 61.8042 56.1536C61.9335 56.4722 62.0001 56.8137 62.0001 57.1586C62.0001 57.5035 61.9335 57.8451 61.8042 58.1636C61.6749 58.4822 61.4853 58.7716 61.2464 59.0152ZM41.966 26.0937C41.966 22.745 40.9945 19.4716 39.1744 16.6872C37.3543 13.9029 34.7672 11.7328 31.7404 10.4513C28.7137 9.1698 25.3831 8.83451 22.1699 9.48781C18.9567 10.1411 16.0051 11.7536 13.6885 14.1215C11.3719 16.4894 9.79431 19.5063 9.15516 22.7906C8.51601 26.0749 8.84405 29.4792 10.0978 32.573C11.3515 35.6668 13.4746 38.3111 16.1987 40.1715C18.9227 42.032 22.1253 43.025 25.4015 43.025C29.7934 43.0208 34.0043 41.2356 37.1099 38.0613C40.2155 34.887 41.962 30.5829 41.966 26.0937ZM57.6138 57.1606L47.6173 46.931L45.7951 48.7934L55.7994 59.0112L57.6138 57.1606Z" fill="black"/>
            </g>
        </svg>
    )
}

export default PlusGlassIcon;