import React from "react";

function MinusGlassIcon(){
    return (
        <svg width="40" height="30" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;magnifying glass minus&#34;">
            <path id="Vector" d="M35.4351 24.125V28.0625H15.7476V24.125H35.4351ZM62.2298 59.0152L58.5167 62.7243C58.2734 62.9689 57.9841 63.1631 57.6655 63.2956C57.3469 63.428 57.0052 63.4962 56.6602 63.4962C56.3151 63.4962 55.9735 63.428 55.6549 63.2956C55.3362 63.1631 55.047 62.9689 54.8036 62.7243L42.7273 50.648C42.2351 50.1555 41.9587 49.4877 41.9587 48.7914C41.9587 48.0952 42.2351 47.4274 42.7273 46.9349L43.2353 46.423L38.8725 42.0642C35.2216 45.0649 30.6571 46.732 25.9317 46.7905C21.2062 46.8491 16.6018 45.2956 12.8777 42.3862C9.15357 39.4769 6.53201 35.3853 5.44523 30.7861C4.35845 26.1869 4.87134 21.3547 6.89934 17.0861C8.92733 12.8176 12.3494 9.36747 16.6012 7.30468C20.8531 5.24188 25.681 4.68954 30.2889 5.73871C34.8968 6.78788 39.0096 9.37594 41.9493 13.0762C44.889 16.7764 46.48 21.3679 46.4601 26.0937C46.4421 30.9463 44.718 35.6379 41.5894 39.3474L45.9521 43.7101L46.4404 43.2219C46.9334 42.7306 47.601 42.4548 48.2969 42.4548C48.9929 42.4548 49.6605 42.7306 50.1535 43.2219L62.2298 55.3021C62.4739 55.5457 62.6677 55.8351 62.7999 56.1536C62.932 56.4722 63.0001 56.8137 63.0001 57.1586C63.0001 57.5035 62.932 57.8451 62.7999 58.1636C62.6677 58.4822 62.4739 58.7716 62.2298 59.0152ZM42.5226 26.0937C42.5226 22.745 41.5296 19.4716 39.6691 16.6872C37.8087 13.9029 35.1644 11.7328 32.0706 10.4513C28.9769 9.1698 25.5725 8.83451 22.2882 9.48781C19.0039 10.1411 15.987 11.7536 13.6191 14.1215C11.2513 16.4894 9.63871 19.5063 8.98541 22.7906C8.33211 26.0749 8.66741 29.4793 9.9489 32.573C11.2304 35.6668 13.4005 38.3111 16.1848 40.1715C18.9692 42.032 22.2426 43.025 25.5913 43.025C30.0805 43.0208 34.3846 41.2356 37.5589 38.0613C40.7332 34.887 42.5184 30.5829 42.5226 26.0937ZM58.5167 57.1606L48.2989 46.931L46.4365 48.7934L56.6621 59.0112L58.5167 57.1606Z" fill="black"/>
            </g>
        </svg>
    )
}

export default MinusGlassIcon;