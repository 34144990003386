import React from "react";

function LinkIcon(){
    return (
        <svg width="40" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;admin links&#34;">
            <path id="Vector" d="M53.22 8.28C58.26 13.35 58.26 21.51 53.22 26.58L48.63 31.14C45.27 34.5 40.53 35.55 36.21 34.41L44.07 26.58L46.35 24.27L48.63 21.99C51.15 19.47 51.15 15.39 48.63 12.87C46.11 10.32 42.03 10.32 39.51 12.87L37.2 15.15L27.06 25.29C25.95 20.97 27 16.23 30.36 12.87L34.92 8.28C39.99 3.24 48.18 3.24 53.22 8.28ZM25.77 40.29L41.79 24.27C43.05 23.01 43.05 20.97 41.79 19.71C40.47 18.42 38.4 18.54 37.2 19.71L21.21 35.73C19.95 36.99 19.95 39.03 21.21 40.29C22.53 41.58 24.6 41.46 25.77 40.29ZM23.49 47.16L35.91 34.71C37.05 39.03 36 43.77 32.64 47.13L28.08 51.72C23.01 56.76 14.85 56.76 9.78 51.72C4.74 46.68 4.74 38.46 9.78 33.42L14.37 28.86C17.73 25.5 22.47 24.45 26.79 25.56L14.37 38.01C11.82 40.53 11.82 44.61 14.37 47.16C16.89 49.68 20.97 49.68 23.49 47.16Z" fill="black"/>
            </g>
        </svg>
    )
}

export default LinkIcon;