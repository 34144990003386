import React from "react";
import "./ClientsTellFrame.css"

function ClientsTellFrame(){
    return (
        <div className="clients-frame">
            <div className="text-wrapper-client">לקוחות מספרים</div>
        </div>
    )
}

export default ClientsTellFrame;