import React from "react";

function BarcodeIcon(){
    return (
        <svg width="40" height="20" viewBox="0 0 60 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;barcode&#34;">
            <path id="Vector" d="M0 45.5V0.5H2.10938V45.5H0ZM3.1473 45.468V0.5H4.21875V45.468H3.1473ZM6.32812 45.468V0.5H7.36605V45.468H6.32812ZM11.5848 45.468V0.5H12.6227V45.468H11.5848ZM15.8036 45.468V0.5H17.8794V45.468H15.8036ZM21.0602 45.468V0.5H22.0982V45.468H21.0602ZM23.1696 45.468V0.5H24.2075V45.468H23.1696ZM25.279 45.468V0.5H26.3169V45.468H25.279ZM29.4643 45.468V0.5H31.5737V45.468H29.4643ZM34.721 45.468V0.5H36.8304V45.468H34.721ZM38.9396 45.468V0.5H41.0491V45.468H38.9396ZM43.1585 45.468V0.5H45.268V45.468H43.1585ZM46.3058 45.468V0.5H48.4152V45.468H46.3058ZM51.596 45.468V0.5H54.7433V45.468H51.596ZM55.7812 45.468V0.5H56.8527V45.468H55.7812ZM57.8906 45.5V0.5H60V45.5H57.8906Z" fill="black"/>
            </g>
        </svg>
    )
}

export default BarcodeIcon;