import React from "react";
import "./SoonToBe.css"

function SoonToBe(){
    return (
        <>
        <div className="soon-frame orange-color" data-color="#f88055">
            <div className="wrap-all">
                <div className="text-topic">בתהליך בנייה <br/> בקרוב</div>
            </div>
        </div>
        </>
    )
}

export default SoonToBe;