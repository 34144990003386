import React from "react";

function ResetIcon(){
    return (
        <svg width="40" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#240;&#159;&#166;&#134; icon &#34;Reset&#34;">
            <path id="Vector" d="M33.75 52.5C38.2001 52.5 42.5502 51.1804 46.2503 48.7081C49.9504 46.2357 52.8343 42.7217 54.5373 38.6104C56.2403 34.4991 56.6858 29.9751 55.8177 25.6105C54.9495 21.2459 52.8066 17.2368 49.6599 14.0901C46.5132 10.9434 42.5041 8.80051 38.1395 7.93234C33.775 7.06417 29.251 7.50975 25.1396 9.21272C21.0283 10.9157 17.5143 13.7996 15.0419 17.4997C12.5696 21.1998 11.25 25.5499 11.25 30V41.625L4.5 34.875L1.875 37.5L13.125 48.75L24.375 37.5L21.75 34.875L15 41.625V30C15 26.2916 16.0997 22.6665 18.1599 19.5831C20.2202 16.4996 23.1486 14.0964 26.5747 12.6773C30.0008 11.2581 33.7708 10.8868 37.4079 11.6103C41.0451 12.3338 44.386 14.1195 47.0083 16.7418C49.6305 19.364 51.4163 22.7049 52.1397 26.3421C52.8632 29.9792 52.4919 33.7492 51.0727 37.1753C49.6536 40.6014 47.2504 43.5298 44.1669 45.5901C41.0835 47.6503 37.4584 48.75 33.75 48.75V52.5Z" fill="black"/>
            </g>
        </svg>
    )
}

export default ResetIcon;